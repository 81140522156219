<template>
  <v-col
    cols="3"
    :class="`cursor ${cta === 'left' ? 'order-first' : ''}`"
    @click="$router.push({ path: `${url}` })"
  >
    <v-card flat>
      <div
        class="black rounded"
        data-blink-ops="quality: smart; blur: 5; scale-crop: 400x300; sharpen: 20; setfill: ece3d2"
        :data-blink-uuid="uuid"
        style="height: 175px;"
      >
        <div class="overlay fill-height d-flex align-center justify-center rounded">
          <div class=" text-center text-uppercase text-h6 white--text">
            {{ label }}
            <v-divider class="primary" />
            <span class="d-block text-overline">Learn More</span>
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    uuid: {
      type: String
    },
    label: {
      type: String
    },
    cta: {
      type: String,
      default: 'right'
    },
    url: {
      type: String
    }
  }
}
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 100ms linear;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.80);
  transition: background-color 250ms linear;
}
</style>
